<template>
    <modal show="show" :modalLarge="true">
   
    <div class="row">
      <div class="modal-header">
        <button type="button" class="btn btn-primary" v-on:click="generatePDF()">PDF</button>
        <button type="button" class="btn-close" v-on:click="close()"></button>
      </div>
      <div style="max-width: 800px;
    margin: auto;
    background: #fff;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
  " ref="mychildcomponent" id='inventoryDetailReport'>
        <div style="text-align: center;
    margin-bottom: 20px;">
          <strong>J SAIMON LAW LTD</strong>
          <div>1 Cranbrook Rd, Broadway Chamber, unit 8, 1<sup>st</sup> Floor</div>
          <div>ILFORD IG1 4DU</div>
          <div>Phone: 02078561616; 07916154584</div>
          <div>Email: info@jsaimonlaw.co.uk</div>
        </div>
  
        <hr style="color:black">
  
        <div style="text-decoration: underline;
    text-align: center;
    margin-bottom: 20px;">
          LETTER OF AUTHORITY
        </div>
  
        <div style="margin-bottom: 20px;">
  
          <table width='100%' class="icon-info-table">
                  <tbody>
  
                    <tr>
                      <td style="width:5%;text-align:right !important;font-size: 16px;">
                        I,</td>
  
                      <td style="width:60%;text-align:center !important;border-bottom: 1px solid black;">
                        <span style="font-weight: bold; font-size: 16px;"></span>{{ authority.name }}
                      </td>
  
                      <td style="width:5%;text-align:right !important">
                        D.O.B</td>
  
                      <td style="width:30%;text-align:center !important;border-bottom: 1px solid black;">
                        <span style=" font-size: 16px;">{{ authority.dob }}</span>
                      </td>
  
  
  
                    </tr>
                    <tr>
                      <td style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                        Address:</td>
  
                      <td colspan="3" style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                        <span style=" font-size: 14px;"></span>{{ authority.address }}
                      </td>
  
                    </tr>
                    <tr>
                      <td style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                        Mobile:</td>
  
                      <td colspan="3" style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                        <span style=" font-size: 14px;"></span>{{ authority.mobile }}
                      </td>
  
                    </tr>
                    <tr>
                      <td style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                        Email:</td>
  
                      <td colspan="3" style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                        <span style=" font-size: 14px;"></span>{{ authority.email }}
                      </td>
  
                    </tr>
                    <tr>
                      <td colspan="4"
                        style="width:95%;text-align:left !important;padding-top:20px;font-size: 14px;letter-spacing: inherit;">
                        <span style=" font-size: 15px;"></span>Hereby authorise J SAIMON LAW LTD of 1 Cranbrook road,
                        Broadway
                        Chambers, Ilford, IG1
                      </td>
  
                    </tr>
                    <tr>
                      <td colspan="4" style="width:95%;text-align:left !important;co">
                        <span style=" font-size: 15px;"></span>4DU to deal with my
                      </td>
  
                    </tr>
                    <tr>
                      <td colspan="3" style="width:90%;text-align:left !important;border-bottom: 1px solid black;">
                        <span style=" font-size: 15px;"></span>{{ authority.matter }}
                      </td>
                      <td style="width:5%;text-align:left !important;copadding-left:10px">
                        <span style=" font-size: 15px;">I also authorised</span>
                      </td>
  
                    </tr>
                    <tr>
                      <td colspan="4" style="width:95%;text-align:left !important;copadding-left:10px">
                        <span style=" font-size: 14px;"></span>J Saimon Ltd to pass my details where necessary for the
                        application.
                      </td>
  
                    </tr>
                    
  
                  </tbody>
                </table>
  
                <table width='100%' style="margin-top: 40px;width: 100%;" class="icon-info-table">
  
                  <tr>
                      <td style="width:5%;text-align:left !important;padding-top:130px;font-size: 14px;">
                        Signature:</td>
  
                      <td
                        style="width:35%;border-bottom: 1px solid;text-align:center !important;padding-top:87px;padding-left:10px">
                        <img :src="authority.signature" style="width:auto;max-width:88px; max-height:94px">
  
                      </td>
                      <td colspan="2" style="width:60%;">
  
                      </td>
                    </tr>
                    <tr>
                      <td style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                        Name:</td>
  
                      <td
                        style="width:35%;border-bottom: 1px solid;text-align:left !important;padding-top:20px;padding-left:10px">
                        <span style=" font-size: 14px;"></span>{{ authority.printedName }}
                      </td>
                      <td colspan="2" style="width:60%;">
  
                      </td>
                    </tr>
                    <tr>
                      <td style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                        Date:</td>
  
                      <td
                        style="width:35%;border-bottom: 1px solid;
                                                                          text-align:left !important;padding-top:20px;padding-left:10px">
                        <span style=" font-size: 14px;"></span>{{ authority.date }}
                      </td>
                      <td colspan="2" style="width:60%;">
  
                      </td>
                    </tr>
                </table>
  
        </div>
  
  
  
  
      </div>
    </div>
</modal>
  </template>
    
  <script>
  import moment from "moment";
  import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  export default {
    props: ['customerEmail','show'],
    name: 'LetterOfAuthority',
    data() {
      return {
        authority: {
          name: '',
          dob: '',
          address: '',
          mobile: '',
          email: '',
          matter: '',
          signature: '',
          printedName: '',
          date: '',
        }
      }
    },
    methods: {
        close: function () {
                
    
                this.$emit('close');
            },
      getCustomerDetail: function () {
        var root = this;
        var token = '';
        if (this.$session.exists()) {
          token = localStorage.getItem('token');
        }
        //var email = localStorage.getItem('UserName');
  
        this.$https.get('/Contact/ContactDetail?caseCustomer=true' + '&email=' + this.customerEmail, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
          if (response.data != null) {
             
            root.authority.name = response.data.englishName;
            root.authority.dob = response.data.registrationDate;
            root.authority.address = response.data.billingAddress;
            root.authority.mobile = response.data.telephone;
            root.authority.email = response.data.email;
            root.authority.matter = response.data.remarks;
            root.authority.signature ='data:image/png;base64,' + response.data.signaturePath;
            root.authority.printedName = response.data.signatureName;
            root.authority.date = response.data.signatureDate;
          }
  
        });
      },
      async generatePDF() {
      const html = document.querySelector('#inventoryDetailReport'); // Element to be converted
      const canvas = await html2canvas(html);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      });
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('download.pdf');
    },
      printInvoice: function () {
  
  
        var form = new FormData();
        form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
        var root = this;
        var token = '';
        if (root.$session.exists()) {
          token = localStorage.getItem('token');
        }
        root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
          .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var date = moment().format('DD MMM YYYY');
             {
              link.setAttribute('download', 'Supplier Balance Report ' + date + '.pdf');
            }
            document.body.appendChild(link);
            link.click();
  
          });
      }
    },
    mounted() {
      this.getCustomerDetail();
    }
  }
  
  </script>
    
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin: 0;
    background: #f7f7f7;
  }
  </style>